<template>
  <div class="main-contract">
    <p class="main-contract__text"
      :class="{'main-contract__text-animated': isAnimate}">
      If you would like to discuss a Teleport with one of our travel agents, contact us here
    </p>
    <router-link to="/enquire" class="enquire__btn btn btn-main" :class="{'enquire__btn-animated': isAnimate}">Enquire</router-link>
  </div>
</template>

<script>
export default {
  name: 'AppContact',

  props: {
    isAnimate: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  mounted () {
    if (!this.isAnimate) {
      return
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const text = document.querySelector('.main-contract__text-animated')
          const button = document.querySelector('.enquire__btn-animated')
          text.classList.add('main-contract__text-animated--animate')
          button.classList.add('enquire__btn-animated--animate')
        }
      })
    })
    observer?.observe(document.querySelector('.main-contract'))
  }
}
</script>

<style scoped>
.main-contract {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-contract__text {
  position: relative;
  max-width: 556px;
  font-family: 'ABC Diatype';
  font-weight: 400;
  text-align: center;
}

.main-contract__text-animated {
  opacity: 0;
}

.enquire__btn-animated {
  opacity: 0;
}

.main-contract__text-animated--animate {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.enquire__btn-animated--animate {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@media (min-width: 799px) {
  .main-contract {
    margin-top: 66px;
  }

  .main-contract__text {
    font-size: 24px;
    line-height: 34px;
  }
}

@media (max-width: 798px) and (min-width: 501px){
  .main-contract {
    margin-top: 60px;
  }

  .main-contract__text {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (min-width: 501px) {
  .main-contract__text {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .main-contract__text {
    margin-top: 95px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 21px;
  }
}

@keyframes block-animation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes block-animation {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

@-moz-keyframes block-animation {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
</style>
