<template>
  <section class="details">
    <h2 class="heading-xs">Details</h2>
    <div class="details__cards">
      <AppDetailsCard v-for="(card, idx) of detailsCard" :key="idx" :card="card" />
    </div>
  </section>
</template>

<script>
import { detailsCard } from '@/composition/useState'
import AppDetailsCard from '@/components/UI/AppDetailsCard.vue'
export default {
  name: 'AppDetails',
  components: { AppDetailsCard },
  setup () {
    return { detailsCard }
  }
}
</script>

<style scoped>
* {
  text-align: center;
  font-family: 'Skema Pro Display', Helvetica, Arial, serif;
}

.details {
  padding: var(--padding-xl) 0;
  position: relative;
  overflow: hidden;
  background-color: var(--black);
}

.heading-xs {
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.details__cards {
  max-width: var(--size-5);
  margin: 0 auto;
  padding: 0 var(--padding-s);
  display: grid;
}

@media (min-width: 1025px) {
  .heading-xs {
    margin-bottom: 94px;
  }

  .details__cards {
    gap: 24px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .heading-xs {
    margin-bottom: 71px;
  }

  .details__cards {
    gap: 18px;
  }
}

@media (min-width: 630px) {
  .details__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 430px) {
  .heading-xs {
    margin-bottom: 44px;
  }

  .details__cards {
    gap: 15px;
  }
}
</style>
