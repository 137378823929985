<template>
  <section class="how">
    <div class="how__content">
      <p class="text-l">We create, without limitation, the story you want to experience.</p>
      <div class="how__title">
        <span class="strip"></span>
        <h2 class="text-gradient heading-l">how?</h2>
        <div class="arrow">
          <span class="arrow--left"></span>
          <span class="strip"></span>
          <span class="arrow--right"></span>
        </div>
      </div>
      <p class="text-m">It all starts with the Teleport Dream Discovery Interview. Then, like in film and theatre, a script is created, locations are found, and actors are cast. And then reality is redefined around you.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppHow',
  setup () {}
}
</script>

<style scoped>
* {
  text-align: center;
  font-family: 'Skema Pro Display', Helvetica, Arial, serif;
}

.how {
  padding: var(--padding-xl) 0;
  position: relative;
  overflow: hidden;
  background-color: var(--black);
}

.how__content {
  max-width: calc(var(--size-2) + 2 * var(--padding-s));
  margin: 0 auto;
  padding: 0 var(--padding-s);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.how__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.how__title .strip {
  display: block;
  width: 1px;
  background-color: var(--brand);
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.arrow--right,
.arrow--left {
  position: relative;
  display: block;
  overflow: hidden;
}

.arrow--left {
  transform: rotate(90deg);
  right: -1px;
}

.arrow--right {
  right: 1px;
}

.arrow--right:before,
.arrow--left:before {
  content: '';
  width: 200%;
  height: 200%;
  display: block;
  border: 1px solid var(--brand);
  border-radius: 50%;
}

p {
  font-weight: 300;
}

p:last-of-type {
  font-style: italic;
}

@media (min-width: 1025px) {
  .how__title {
    margin: 30px 0 40px;
  }

  .how__title .strip {
    height: 160px;
  }

  h2 {
    margin: 40px 0 10px;
  }

  .arrow--right,
  .arrow--left {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .how {
    padding-bottom: 113px;
  }

  .how__title {
    margin: 23px 0 30px;
  }

  .how__title .strip {
    height: 120px;
  }

  h2 {
    margin: 30px 0 7px;
  }

  .arrow--right,
  .arrow--left {
    width: 12px;
    min-width: 12px;
    height: 12px;
  }
}

@media (max-width: 430px) {
  .how {
    padding-bottom: 115px;
  }

  .how__title {
    margin: 8px 0 18px;
  }

  .how__title .strip {
    height: 100px;
  }

  h2 {
    margin: 12px 0 2px;
  }

  .arrow--right,
  .arrow--left {
    width: 10px;
    min-width: 10px;
    height: 10px;
  }
}
</style>
