<template>
  <header
    class="header blocks-wrapper"
    :class="{'header__nav--open': isNavOpen}"
  >
    <div class="header__wrapper">
      <AppHeaderNav
        :isNavOpen="isNavOpen"
        class="header__nav"
        :class="{'no-events': isShowIntro}"
        @changeNavStatus="$emit('changeNavStatus')"
      />
      <router-link
        to="/"
        class="main-link"
        :class="{'no-events': isShowIntro}"
        @click="$emit('goHome')"
      >
        teleport
      </router-link>
      <router-link
        to="/enquire"
        class="btn btn-brand"
        :class="{'no-events': isShowIntro}"
      >
        Enquire
      </router-link>
    </div>
  </header>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import AppHeaderNav from '@/components/AppHeaderNav.vue'
import { isShowIntro } from '@/composition/useState'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderNav
  },

  props: {
    isNavOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  emits: ['changeNavStatus', 'goHome'],

  setup () {
    const route = useRoute()
    const router = useRouter()

    const goToHash = (key) => {
      if (route.hash.includes(key)) {
        return
      }

      const hashName = `#${key}`
      router.push('/teleportation/')
      setTimeout(() => {
        window.location.hash = hashName
      }, 1)
    }

    return {
      goToHash,
      isShowIntro
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
}
.header__wrapper {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.header__nav {
  justify-self: flex-start;
}

.btn-brand {
  justify-self: flex-end;
}

.main-link {
  grid-column: 2/3;
  justify-self: center;
  font-family: 'ABC Diatype', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 1;
  line-height: normal;
}

.header__where .main-link {
  color: var(--black);
}

.header__nav--open .main-link {
  color: var(--white);
}

@media (min-width: 1025px) {
  .header {
    min-height: 90px;
    padding: 0 var(--size-6);
  }

  .main-link {
    font-size: 24px;
    letter-spacing: 3.1px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .header {
    min-height: 70px;
    padding: 0 30px;
  }

  .main-link {
    font-size: 18.7px;
    letter-spacing: 2.4px;
  }
}

@media (max-width: 430px) {
  .header {
    min-height: 50px;
    padding: 0 16px;
  }

  .main-link {
    font-size: 16.5px;
    letter-spacing: 2.1px;
  }
}
</style>
