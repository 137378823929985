<template>
  <section class="about">
    <div ref="figure" class="about__figure">
      <span class="about__circle"></span>
      <span class="about__circle"></span>
      <span class="about__circle"></span>
      <span class="about__circle"></span>
    </div>
    <div class="about__content">
      <p class="text-l about__text">By mixing fact with fiction, they become indistinguishable. Our travellers do not know what is real or what is staged.</p>
      <div class="about__gallery">
        <img class="img--center" src="../assets/images/about/random_2.jpg" alt="man with a book">
        <img class="img--right" src="../assets/images/about/random_3.jpg" alt="woman">
        <div class="gallery__block">
          <img class="img--left" src="../assets/images/about/random_1.jpg" alt="crowd">
          <p class="text-l about__gallery-text">We incorporate actors into the real world, sometimes well in advance of the Teleport. What is usually only experienced on the screen or stage is now experienced in real life.</p>
        </div>
        <img class="img--bottom" src="../assets/images/about/random_4.jpg" alt="elderly">
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'

export default {
  name: 'AppAbout',
  setup () {
    const figure = ref(null)
    const coeff = ref(0.1)
    const coeffValues = {
      desktop: 0.5,
      mobile: 0.23
    }

    const parallaxOnScroll = () => {
      const offset = window.scrollY
      figure.value.style.backgroundPositionY = (-offset * coeff.value) + 'px'
    }

    const checkWidth = () => {
      const windowWidth = document.documentElement.clientWidth
      if (windowWidth >= 1025 && coeff.value !== coeffValues.desktop) {
        coeff.value = coeffValues.desktop
      } else if (coeff.value !== coeffValues.mobile) {
        coeff.value = coeffValues.mobile
      }
    }

    onMounted(() => {
      checkWidth()
      window.addEventListener('resize', checkWidth)
      window.addEventListener('scroll', parallaxOnScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth)
      window.removeEventListener('scroll', parallaxOnScroll)
    })

    return { figure }
  }
}
</script>

<style scoped>
.about {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.about__figure {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: space-between;
  background-position: top;
  background-size: cover;
}

.about__circle {
  display: block;
  position: relative;
  overflow: hidden;
}

.about__circle:nth-child(even) {
  justify-self: flex-end;
}

.about__circle:nth-child(2) {
  transform: rotate(90deg);
}

.about__circle:nth-child(3) {
  transform: rotate(270deg);
}

.about__circle:nth-child(4) {
  transform: rotate(180deg);
}

.about__circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: radial-gradient(transparent 70%, var(--black) 71%);
}

.about__content {
  width: 100%;
  height: fit-content;
  background-color: var(--black);
}

p {
  color: var(--white);
  text-align: center;
  font-family: 'Skema Pro Display', serif;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

img {
  width: 100%;
  border-radius: var(--img-radius);
  object-fit: cover;
  max-height: 440px;
  min-width: 95px;
}

.about__gallery {
  max-width: 1440px;
  margin: var(--padding-xl) auto 0;
  display: grid;
  grid-template-rows: repeat(3, auto);
}

.gallery__block {
  position: relative;
  display: grid;
  align-items: flex-end;
}

.about__text {
  max-width: calc(var(--size-2) + 2 * var(--padding-s));
  padding: 0 var(--padding-s);
}

.img--left {
  position: absolute;
  width: var(--img-sm);
}

.img--center {
  align-self: center;
}

.img--bottom {
  margin-bottom: var(--padding-xl);
}

.img--right,
.img--center,
.img--bottom {
  max-width: var(--img-xl);
}

.about__text {
  margin: 0 auto;
}

.about__gallery-text {
  max-width: var(--size-2);
  grid-column: 2/3;
}

@media (min-width: 1025px) {
  * {
    --grid-column-s: 280px;
  }

  .about::before {
    content: '';
    display: block;
    height: 300px;
    width: 100%;
    background-color: var(--black);
  }

  .about__figure {
    height: calc(100vw / 2.6);
    background-image: url(../assets/images/about/about_desk.jpg);
  }

  .about__circle {
    width: 60px;
    height: 60px;
  }

  .about__gallery {
    grid-template-columns: var(--grid-column-s) 1fr calc(var(--img-xl) + 60px);
  }

  .gallery__block {
    grid-template-columns: var(--grid-column-s) 1fr var(--grid-column-s);
  }

  .about__text {
    padding-top: 90px;
    padding-bottom: var(--size-3);
  }

  .about__gallery-text {
    margin: 60px 0 75px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .about__figure {
    height: calc(100vw / 2.6);
    background-image: url(../assets/images/about/about_tab.jpg);
  }

  .about__circle {
    width: 50px;
    height: 50px;
  }

  .about__text {
    padding-top: 70px;
    padding-bottom: 109px;
  }
}

@media (max-width: 430px) {
  .about__figure {
    height: calc(100vw / 2.6);
    background-image: url(../assets/images/about/about_mob.jpg);
  }

  .about__circle {
    width: 30px;
    height: 30px;
  }

  .about__text {
    padding-top: 60px;
    padding-bottom: 113px;
  }
}

@media (max-width: 1024px) and (min-width: 740px) {
  * {
    --grid-column-s: 204px;
  }

  .about__gallery {
    grid-template-columns: var(--grid-column-s) 1fr calc(var(--img-xl) + 50px);
  }

  .gallery__block {
    grid-template-columns: var(--grid-column-s) 1fr var(--grid-column-s);
  }

  .about__gallery-text {
    margin: 38px 0 53px;
  }
}

@media (min-width: 740px) {
  .gallery__block {
    grid-column: 1/4;
  }

  .img--left {
    left: 0;
  }

  .img--center {
    grid-column: 2/3;
  }

  .img--right {
    grid-column: 3;
    justify-self: flex-start;
  }

  .img--bottom {
    grid-column: 2;
    justify-self: flex-end;
  }
}

@media (max-width: 739px) {
  .about__gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery__block {
    grid-column: 1/3;
  }

  .img--right {
    justify-self: center;
  }

  .img--bottom {
    margin-top: 13px;
    justify-self: flex-start;
    grid-column: 2/3;
  }

  .about__gallery-text {
    margin: 30px 0 87px;
    padding: 0 30px;
    font-size: 18px;
  }
}

@media (max-width: 739px) and (min-width: 530px) {
  .img--left {
    left: 30px;
    bottom: -50%;
    transform: translateY(30%);
  }
}

@media (max-width: 529px) {
  .img--left {
    left: 30px;
    bottom: -63px;
  }
}
</style>
