<template>
  <nav
    :class="{'nav--open': isNavOpen}"
  >
    <button
      class="btn"
      type="button"
      :aria-expanded="isNavOpen"
      aria-controls="nav__wrapper"
      :class="isNavOpen ? 'btn-close': 'btn-menu'"
      @click="$emit('changeNavStatus')"
    >
    </button>
    <Transition name="fade">
      <div v-if="isNavOpen" class="nav__menu">
        <div class="nav__top">
          <ul class="nav__links--top">
            <li
              v-for="(link, index) in mainLink"
              :key="index"
              class="nav__item"
            >
              <component
                :is="link.tag"
                :to="link.href"
                class="nav__link"
                :class="{'btn-link': link.tag === 'button'}"
                @click.prevent="closeNav(), defineAction(link.text)"
              >
                {{ link.text }}
              </component>
            </li>
          </ul>
          <ul class="nav__links--bottom">
            <li
              v-for="(link, index) in termsLinks"
              :key="index"
              class="nav__item"
            >
              <component
                :is="link.tag"
                :to="link.href"
                class="nav__link"
                :class="{'btn-link': link.tag === 'button'}"
                @click.prevent="closeNav(), defineAction(link.text)"
              >
                {{ link.text }}
              </component>
            </li>
          </ul>
        </div>
        <AppMailLink class="nav__mail"/>
      </div>
    </Transition>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router'
import { linksHeader, isShowModal } from '@/composition/useState.js'
import AppMailLink from '@/components/UI/AppMailLink.vue'
import { skipIntro } from '@/composition/useActions'

export default {
  name: 'HeaderNav',

  components: { AppMailLink },

  props: {
    isNavOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  emits: ['changeNavStatus', 'clicked'],

  setup (props, { emit }) {
    const router = useRouter()
    const mainLink = [...linksHeader.about, ...linksHeader.explore]
    const termsLinks = [...linksHeader.terms]

    const closeNav = () => {
      if (!props.isNavOpen) {
        return
      }
      emit('changeNavStatus', false)
    }

    const defineAction = (controlName) => {
      if (controlName === 'Manifesto') {
        isShowModal.value = true
      } else if (controlName === 'Testimonials') {
        skipIntro()
        goToHash('testimonials')
      }
    }

    const goToHash = (key) => {
      router.push('/')
      setTimeout(() => {
        const blockTop = document.getElementById('testemonials').getBoundingClientRect().top
        window.scrollTo(0, blockTop)
      }, 1)
    }

    return {
      mainLink,
      termsLinks,
      closeNav,
      defineAction
    }
  }
}
</script>

<style scoped>
.nav__menu {
  padding: 28px 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: 40px 1fr 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: var(--black);
}

ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.nav__top {
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav__links--top * {
  font-family: 'Skema Pro Display', serif;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: var(--brand);
}

.nav__links--bottom * {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: rgba(153, 153, 153, 0.80);
}

.nav__link:hover,
.nav__link.router-link-exact-active,
.nav__link.active {
  color: var(--white);
}

.nav__mail {
  grid-row: 3/4;
  align-self: end;
  font-size: 30px;
}

@media (min-width: 1025px) {
}

@media (max-width: 1024px) and (min-width: 431px) {
}

@media (min-width: 431px) {
  .nav__top {
    gap: 60px;
  }

  .nav__links--top {
    gap: 30px;
  }

  .nav__links--top * {
    font-size: 50px;
  }

  .nav__links--bottom {
    gap: 10px;
  }

  .nav__links--bottom * {
    font-size: 18px;
  }
}

@media (max-width: 430px) {
  .nav__top {
    gap: 45px;
  }

  .nav__links--top {
    gap: 22.5px;
  }

  .nav__links--top * {
    font-size: 37.5px;
  }

  .nav__links--bottom {
    gap: 7.5px;
  }

  .nav__links--bottom * {
    font-size: 13.5px;
  }
}

/*
@media (min-width: 1920px) {
  .nav__bottom {
    padding: 0 70px;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    align-self: end;
  }

  .nav__logo {
    grid-column: 2/3;
  }

  .nav__mail {
    justify-self: end;
  }
}

@media  (max-width: 1919px) and (min-width: 1070px) {
  .nav__menu {
    grid-template-rows: 1fr max-content;
    row-gap: 24px;
    line-height: 64px;
  }

  .nav__bottom {
    padding: 0 70px;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .nav__logo {
    grid-column: 2/3;
  }

  .nav__mail {
    justify-self: end;
  }
}

@media (max-width: 1069px) and (min-width: 501px) {
  .nav__menu {
    grid-template-rows: 1fr minmax(min-content, 260px);
    line-height: 60px;
  }
}

@media (min-width: 501px) {
  .nav__menu {
    font-size: 44px;
    padding: 50px 0;
  }

  .nav__item--small {
    font-size: 16px;
    line-height: 26px;
  }

  .nav__logo {
    height: 86px;
  }

  .nav__top {
     gap: 28px;
     margin: 112px 0 72px;
   }
}

@media
(max-width: 500px),
(max-width: 1069px) and (max-height: 900px){
  .nav__menu {
    grid-template-rows: 1fr minmax(min-content, 142px);
    font-size: 32px;
    line-height: 57px;
    padding: 36px 0;
  }

  .nav__item--small {
    font-size: 12px;
    line-height: 19px;
  }

  .nav__top {
    gap: 20px;
    margin: 72px 0;
  }
}

@media (min-width: 1070px) and (max-height: 870px) {
  .nav__menu {
    font-size: 37px;
    line-height: 50px;
  }

  .nav__top {
    margin-top: 60px;
    margin-bottom: 0;
  }

  .nav__logo {
    height: 70px;
  }
}

@media (max-width: 1069px) and (max-height: 730px) {
  .nav__menu {
    row-gap: 10px;
    font-size: 27px;
    line-height: 43px;
  }

  .nav__top {
    margin: 50px 0 0;
  }

  .nav__bottom {
    gap: 10px;
  }
} */
</style>
