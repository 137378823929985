<template>
  <section class="home">
    <div class="home__planet"></div>
    <div class="home__content">
      <h1>
        <span>Go where anything is</span>
        <strong class="text-gradient">possible</strong>
      </h1>
      <p>Teleport is an event and travel company that redesigns reality to create bespoke real-life experiences.</p>
    </div>
    <div class="home__scroll">
      Scroll to Discover
    </div>
  </section>
</template>

<script>
import { isShowIntro, isShowModal } from '@/composition/useState'

export default {
  name: 'AppHomeScreen',
  setup () {
    return { isShowModal, isShowIntro }
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
  padding: 0 var(--padding-s);
  display: grid;
  grid-template-rows: 90px 1fr 90px;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  overflow: hidden;
}

.home,
.home * {
  font-family: 'Skema Pro Display', Helvetica, Arial, serif;
  font-style: normal;
  position: relative;
}

.home::before {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--black);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home__content {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

h1 * {
  line-height: 1;
}

strong {
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  transform: translateX(50%);
  left: -47%;
}

p {
  font-weight: 300;
  line-height: 130%;
  text-align: center;
}

.home__scroll {
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: end;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.home__scroll::before {
  content: '';
  display: block;
  background: url(../assets/icons/mouse.svg)no-repeat center/contain;
}

@media (min-width: 1025px) {
  .home {
    min-height: 580px;
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .home::before {
    width: 70.5vw;
    height: 110%;
    background-image: url('../assets/images/main/world_desk.jpg');
  }

  .home__content {
    margin-top: 53px;
  }

  h1 {
    font-size: 40px;
    gap: 30px;
  }

  p {
    max-width: 800px;
    margin: 40px 0 0;
    font-size: 24px;
  }

  .home__scroll {
    letter-spacing: 1px;
    font-size: 14px;
    gap: 20px;
  }

  .home__scroll::before {
    width: 24px;
    height: 40px;
  }
}

@media (max-width: 1024px) {
  .home::before {
    height: 100%;
  }

  .home__scroll {
    font-size: 10.5px;
    letter-spacing: 0.75px;
    gap: 15px;
  }

  .home__scroll::before {
    width: 18px;
    height: 30px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .home {
    min-height: 500px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .home::before {
    width: 86vw;
    background-image: url('../assets/images/main/world_desk.jpg');
  }

  h1 {
    font-size: 30px;
    gap: 22.5px;
  }

  p {
    max-width: 660px;
    margin: 30px 0 0;
    font-size: 18px;
  }
}

@media (max-width: 430px) {
  .home {
    min-height: 440px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .home::before {
    width: 100vw;
    background-image: url('../assets/images/main/world_mob.jpg');
  }

  .home__content {
    padding-top: 45px;
  }

  h1 {
    font-size: 18px;
    gap: 9.6px;
  }

  p {
    max-width: unset;
    margin: 15.4px 0;
    font-size: 13px;
  }
}
/* Адаптивные размеры заголовка */

@media (min-width: 1146px) {
  h1 strong {
    font-size: 120px;
    letter-spacing: 60px;
  }
}

@media (max-width: 1145px) and (min-width: 1025px) {
  h1 strong {
    font-size: calc(90px + 30 * ((100vw - 1025px) / (1145 - 1025)));
    letter-spacing: 60px;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  h1 strong {
    font-size: 90px;
    letter-spacing: 45px;
  }
}

@media (max-width: 899px) and (min-width: 431px) {
  h1 strong {
    font-size: calc(46px + 44 * ((100vw - 376px) / (899 - 376)));
    letter-spacing: calc(13px + 32 * ((100vw - 376px) / (899 - 376)));
  }
}

@media (max-width: 430px) and (min-width: 320px) {
  h1 strong {
    font-size: calc(32px + 14 * ((100vw - 320px) / (375 - 320)));
    letter-spacing: 13px;
  }
}

@media (max-width: 319px) {
  h1 strong {
    font-size: 32px;
    letter-spacing: 13px;
  }
}
</style>
