<template>
  <AppTyped
    ref="typed"
    :options="options"
    class="typed__wrapper"
  >
    <article></article>
  </AppTyped>
</template>

<script>
import AppTyped from '@/components/UI/AppTyped.vue'
import { onMounted, onBeforeUnmount, ref } from 'vue'

export default {
  name: 'AppTypedCard',
  components: { AppTyped },
  props: {
    text: {
      type: Array,
      default: () => [],
      required: true
    },
    typeSpeed: {
      type: Number,
      default: 30,
      required: false
    }
  },
  setup (props) {
    const typed = ref(null)
    const options = {
      strings: props.text,
      contentType: 'html',
      fadeOut: true,
      loop: false,
      showCursor: false,
      typeSpeed: props.typeSpeed
    }
    onMounted(() => typed.value.start())
    onBeforeUnmount(() => typed.value.destroy())
    return { options, typed }
  }
}
</script>

<style scoped>
.typed__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

article {
  z-index: 2;
  align-self: center;
  font-weight: 400;
  text-align: center;
  justify-self: center;
}

@media (min-width: 1920px) {
  article {
    max-width: 900px;
    font-size: 60px;
    line-height: 65px;
  }
}

@media (max-width: 1919px)  and (min-width: 799px) {
  article {
    font-size: 44px;
    line-height: 47px;
    max-width: 772px;
    padding: 0 36px;
  }
}

@media (max-width: 798px)  and (min-width: 501px) {
  article {
    padding: 50px;
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 798px) {
  article {
    padding: 0 50px;
  }
}

@media (max-width: 500px) {
  article {
    padding: 0 24px;
  }
}

@media (max-width: 500px) and (max-height: 570px){
  article {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 500px) and (min-height: 571px){
  article {
    font-size: 24px;
    line-height: 34px;
  }
}
</style>
