<template>
  <div class="details-card">
    <h3 class="text-s details-card__title">{{ card.title }}</h3>
    <h4 class="heading-s">{{ card.subtitle }}</h4>
    <span class="details-card__line"></span>
    <p class="text-s details-card__text">{{ card.text }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppDetailsCard',
  props: {
    card: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  setup () {}
}
</script>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
  line-height: 130%;
  text-align: left;
}

.details-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 30px;
  border: 1px solid #FFDEAC;
}

.heading-s {
  font-family: 'Skema Pro Display', Helvetica, Arial, serif;
  color: var(--brand);
  line-height: 100%;
}

.details-card__title {
  font-weight: 500;
  text-transform: uppercase;
}

.details-card__text {
  font-weight: 400;
  line-height: 130%;
  opacity: .6;
}

.details-card__line {
  display: block;
  border-top: 1px solid #333;
}

@media (min-width: 1025px) {
  .details-card {
    min-height: 250px;
    padding: 40px;
    gap: 10px;
  }

  .details-card__line {
    width: 40px;
    margin: 10px 0;
  }

  .details-card__title {
    letter-spacing: 1px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .details-card {
    min-height: 188px;
    padding: 30px;
    gap: 7px;
  }

  .details-card__line {
    width: 30px;
    margin: 7px 0;
  }

  .details-card__title {
    letter-spacing: 0.75px;
  }
}

@media (max-width: 430px) {
  .details-card {
    min-height: 190px;
    padding: 29px;
    gap: 6px;
  }

  .details-card__line {
    width: 29px;
    margin: 8px 0;
  }

  .details-card__title {
    letter-spacing: 0.7px;
  }
}
</style>
