import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/journeys',
    name: 'journeys',
    component: () => import(/* webpackChunkName: "journeys" */ '../views/JourneysView.vue')
  },
  {
    path: '/journeys/:id',
    name: 'journeysItem',
    component: () => import(/* webpackChunkName: "journeys" */ '../views/JourneysDetails.vue')
  },
  {
    path: '/your-journey',
    name: 'yourJourney',
    component: () => import(/* webpackChunkName: "journeys" */ '../views/YourJourney.vue')
  },
  {
    path: '/qa',
    name: 'qa',
    component: () => import(/* webpackChunkName: "qa" */ '../views/QAView.vue')
  },
  {
    path: '/enquire',
    name: 'enquire',
    component: () => import(/* webpackChunkName: "enquire" */ '../views/EnquireView.vue')
  },
  {
    path: '/brand-story',
    name: 'brand-story',
    component: () => import(/* webpackChunkName: "brand-story" */ '../views/BrandStory.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import(/* webpackChunkName: "terms-conditions" */ '../views/TermsConditions.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
