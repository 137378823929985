<template>
  <transition name="fade">
    <AppIntro v-if="isShowIntro" class="intro" />
  </transition>
  <main>
    <AppHomeScreen />
    <AppAbout />
    <AppHow />
    <AppDetails />
    <AppTestimonials />
    <section class="qa">
      <div class="qa__content">
        <AppQA
          :tag="'h2'"
          :version="'short'"
          :isAnimate="true"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { isShowModal, isShowIntro } from '@/composition/useState'
import { defineIsShowIntro, skipIntro } from '@/composition/useActions'
import AppTestimonials from '@/components/AppTestimonials.vue'
import AppQA from '@/components/AppQA.vue'
import AppIntro from '@/components/UI/AppIntro.vue'
import AppHomeScreen from '@/components/AppHomeScreen.vue'
import AppAbout from '@/components/AppAbout.vue'
import { useMetaTag } from '@/composition/useMetaTag'
import { onBeforeRouteLeave } from 'vue-router'
import AppHow from '@/components/AppHow.vue'
import AppDetails from '@/components/AppDetails.vue'

export default {
  components: {
    AppDetails,
    AppHow,
    AppAbout,
    AppIntro,
    AppTestimonials,
    AppQA,
    AppHomeScreen
  },
  setup () {
    onBeforeRouteLeave(() => {
      skipIntro()
    })
    useMetaTag(window.location.href, 'home')
    defineIsShowIntro()
    return { isShowModal, isShowIntro }
  }
}
</script>

<style scoped>
.intro {
  position: fixed;
  z-index: 1;
}

.qa {
  padding: var(--padding-xl) 0;
  background-color: var(--black);
}

.qa__content {
  max-width: var(--size-5);
  margin: 0 auto;
  padding: 0 var(--padding-s);
  display: flex;
  flex-direction: column;
}

.qa__content :deep(h2) {
  margin: 0 auto;
  font-family: 'Skema Pro Display', serif;
}

:deep(.where-to__bottom) {
  margin-bottom: 0px;
}

:deep(.where-to__card ) {
  margin-bottom: -2px;
}

.map-scale-animation {
  display: block;
  animation-name: map-scale-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.map-scale-animation-reverse {
  display: block;
  animation-name: map-scale-animation-reverse;
  animation-duration: 2s;
}

@media (min-width: 1025px)  {
  .where-to {
    padding: 52px;
  }

  :deep(.where-to__bottom) {
    padding: 0px 52px 52px;
  }

  :deep(.where-to__card) {
    margin-bottom: 0px;
  }

  .qa__content :deep(h2) {
    margin-bottom: 62px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .map-scale-animation {
    animation-name: map-scale-animation-501;
  }

  :deep(.where-to__bottom) {
    padding: 0px 24px 64px;
  }

  .qa__content :deep(h2) {
    margin-bottom: 46.5px;
  }
}

@media (max-width: 430px) {
  .map-scale-animation {
    animation-name: map-scale-animation-501;
  }

  :deep(.where-to__bottom) {
    padding: 0px 16px 16px;
  }

  .qa__content :deep(h2) {
    margin-bottom: 46px;
  }
}

@keyframes map-scale-animation {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
  }
}

@keyframes map-scale-animation-reverse {
  0% {
    background: #4A83EB;
  }

  100% {
    background: transparent;
  }
}

@keyframes map-scale-animation-501 {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
    height: 100vh;
  }
}

@-webkit-keyframes map-scale-animation {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
  }
}

@-webkit-keyframes map-scale-animation-501 {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
    height: 100vh;
  }
}

@-moz-keyframes map-scale-animation {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
  }
}

@-moz-keyframes map-scale-animation-501 {
  0% {
    background: transparent;
  }

  100% {
    background: #4A83EB;
    height: 100vh;
  }
}
</style>
