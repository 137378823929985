
import { ref } from 'vue'
export const isShowModal = ref(false)
export const isShowIntro = ref(false)
export const isShowHeader = ref(true)
const aboutLink = [
  { tag: 'router-link', href: '/where-to', text: 'Where to?' },
  { tag: 'router-link', href: '/journeys', text: 'Journeys' }
]
const termsLink = [
  { tag: 'router-link', href: '/brand-story', text: 'Brand story' },
  { tag: 'router-link', href: '/terms-conditions', text: 'Terms and conditions' },
  { tag: 'router-link', href: '/legal', text: 'Legal' }
]
const exploreLink = [
  { tag: 'button', text: 'Testimonials' },
  { tag: 'router-link', href: '/qa', text: 'Q&A' },
  { tag: 'router-link', href: '/enquire', text: 'Enquire' }
]
const manifestoLink = [
  { tag: 'button', text: 'Manifesto' }
]

export const linksHeader = {
  about: [aboutLink[1]],
  explore: [exploreLink[1], exploreLink[2]],
  terms: [
    ...termsLink.slice(0, 1),
    ...manifestoLink,
    ...termsLink.slice(1)
  ]
}

export const linksFooter = {
  about: [
    aboutLink[1],
    ...manifestoLink
  ],
  terms: [...termsLink.slice(0, 2)],
  explore: [...exploreLink.slice(1)]
}

const docMeta = {
  title: 'Teleport Terms and Conditions | Luxury Travel Guidelines | Teleport',
  description: 'Review the terms and conditions for Teleport\'s luxury travel experiences. Understand the guidelines and policies that govern your journey.'
}
export const metaTagsContent = {
  home: {
    title: 'Luxury Travel Experiences | Exclusive Destinations | Teleport',
    description: 'Teleport offers bespoke luxury travel planning and VIP concierge services for high-end retreats and immersive journeys. Discover our exclusive destinations for a transformative and personalized adventure.'
  },
  whereTo: {
    title: 'Exclusive Destinations | Uncharted Luxury Travel | Teleport ',
    description: 'Discover Teleport\'s inspirational retreats, escapes, and uncharted luxury travel destinations for a transformative and personalized adventure.'
  },
  journeys: {
    title: 'Experiential Luxury Journeys | Transformative Travel | Teleport',
    description: 'Embark on immersive and luxury journeys beyond expectations with Teleport. Indulge in personalized adventures and curated itineraries for unforgettable memories.'
  },
  journey1: {
    title: 'Unforgettable Anniversary Gift | Time Travel Journey | Teleport',
    description: 'Celebrate your anniversary with a unique and valuable journey. Teleport offers a time travel experience to revisit cherished moments in your love story.'
  },
  journey2: {
    title: 'Embrace Change and Visualize the Future | Team-Building Journey | Teleport',
    description: 'Navigate change and embrace new opportunities with a team-building journey by Teleport. Create unforgettable memories while experiencing rapid change and business transformation.'
  },
  journey3: {
    title: 'Experience an Extraordinary Another Life | Uncover New Perspectives | Teleport',
    description: 'Step into someone else\'s shoes with Teleport and explore the realms of anonymity and everyday adventures. Discover new perspectives on yourself and the world through a unique journey in our exclusive destinations.'
  },
  journey4: {
    title: 'Experience an Extraordinary Another Life | Uncover New Perspectives | Teleport',
    description: 'Step into someone else\'s shoes with Teleport and explore the realms of anonymity and everyday adventures. Discover new perspectives on yourself and the world through a unique journey in our exclusive destinations.'
  },
  qa: {
    title: 'Teleport FAQ | Bespoke Luxury Travel Questions | Teleport',
    description: 'Learn about our personalized adventures, VIP concierge services, and customized luxury vacations. Explore the possibilities and learn more about Teleport.'
  },
  enquire: {
    title: 'Start Your Journey | Contact Us for Unforgettable Travel Experiences | Teleport',
    description: 'Begin your journey with Teleport by contacting us. Fill in the form or email us to discuss your unforgettable travel experiences.'
  },
  brandStory: {
    title: 'The Story Behind Teleport | Creating Dreams and Experiences | Teleport',
    description: 'Discover the brand story of Teleport, from a childhood dream to the creation of unforgettable experiences. Learn about our team and passion for storytelling.'
  },
  termsConditions: { ...docMeta },
  legal: { ...docMeta }
}
export const map = {
  past: 'If you could go back in time, what time and place would you go to? Travel with Teleport and make the past present.',
  dream: 'If a dream could become a reality, what dream would you make real? Travel with Teleport and explore your dreams.',
  anotherLife: 'If you weren’t you, who would you be? Travel with Teleport to experience what it’s like to live an entirely different life.',
  secondChance: 'Travel to choices you made in the past and explore alternate outcomes. Travel with Teleport and rewrite your history.',
  fiction: 'If you could travel to a favourite book or film, which would it be? Travel with Teleport and experience fiction as reality.',
  wisdom: 'The destination where questions find answers. Travel with Teleport and return wiser.',
  inspiration: 'If inspiration was a place, wouldn’t it be a great idea to go there? Travel with Teleport and free your imagination.',
  wonder: 'If you’re looking for incredible, you’ll find it in Wonder. Travel with Teleport and be amazed.',
  future: 'If you could travel into the future, how far would you go? Travel with Teleport and see what tomorrow brings today.'
}

export const isMapAnimating = ref(false)
export const mapItemId = ref(1)
export const mapAnimationTitle = ref('')
export const mapAnimationText = ref('')
export const mapAnimationTimerOne = ref(null)
export const mapAnimationTimerTwo = ref(null)

export const mapAnimationTitles = {
  0: 'Past',
  1: 'Dream',
  2: 'Another Life',
  3: 'Second Chance',
  4: 'Fiction',
  5: 'Wisdom',
  6: 'Inspiration',
  7: 'Wonder',
  8: 'Future'
}

export const mapAnimationTexts = {
  0: map.past,
  1: map.dream,
  2: map.anotherLife,
  3: map.secondChance,
  4: map.fiction,
  5: map.wisdom,
  6: map.inspiration,
  7: map.wonder,
  8: map.future
}

export const testimonials = [
  {
    author: 'Martina Klingvall',
    profession: 'founder and CEO Telness Group',
    text: 'Teleport created a real but redesigned world that revolved around my sister. A celebration of all that she is and an inspiration for what she wants to be. It was beautiful and magical, and impossible. I think it’s the greatest gift I could have given her. I don’t know how they did. She said it was the happiest day of her life.',
    img: 'martina.png'
  },
  {
    author: 'Mathias Thulin',
    profession: 'Founder Getaccept.com',
    text: 'I was caught by surprise, to be honest. I knew I was going on a Teleport, but you get pulled in so deep. It was powerful and real. Genuinely moving in ways I haven’t felt in years.',
    img: 'mathias.png'
  },
  {
    author: 'Jonas Antonsson',
    profession: 'Co-Founder & CEO  RawFury',
    text: 'I wanted my company to be able to imagine the future. Because if you can imagine it, you can build it. Teleport allowed us to travel into the future. To see all that we can be, made us fearless. It was amazing. I think we’re all changed by it.',
    img: 'jonas.png'
  },
  {
    author: 'Drs. Maarten van der Linde',
    profession: 'psychologist and system therapist',
    text: 'By using the full force of our imagination, fuelled by dreams and desires, we can unlock our own potential. Don’t we owe that to ourselves?',
    img: 'maarten.jpg'
  },
  {
    author: 'Adriaan Ligtenberg',
    profession: 'Candidate-1st Co-founder',
    text: 'At Candidate-1st, our primary goal is to foster the development of both individuals and organizations. Teleport\'s events have left a remarkable impression, as they effectively facilitate introspection, inspire transformation, and reinforce core values.',
    img: 'adriaan.jpg'
  },
  {
    author: 'Marcus Skinnar,',
    profession: 'Serial entrepreneur',
    text: 'Teleport is amazing - I have lived an adventurous life - crawling in 10,000-year-old undiscovered graves in Somali caves and been in session with the shaman of the elusive Deer People of Northern Mongolia / Russia. I have been hunted and negotiated with both the Chinese Triads and the Italian Mafia... and even so, Teleport can bring me experiences beyond this. It\'s the next level of creating a parallel universe where nothing is impossible.',
    img: 'marcus.jpg'
  },
  {
    author: 'David Glass',
    profession: 'One of the UK’s most prominent Theatre Directors',
    text: 'Teleport is visionary and playful and reawakens, at an essential level, what it is to be human.',
    img: 'david.jpg'
  }
]

export const qaList = [
  {
    label: 'What’s possible?',
    text: [
      'To this, there is a simple answer: absolutely anything. We offer the opportunity to travel to impossible places. By blurring the division between fact and fiction in the real world, we can design any reality for our clients.'
    ]
  },
  {
    label: 'Where can I travel to?',
    text: [
      'What dream would you make real? Experience what it’s like to live an entirely different life. Travel to choices you made in the past and explore alternate outcomes. If you could travel to a favourite book or film, which would it be? Do you want to visit the past or the future?',
      'Whether you\'re looking for adventure, escape, inspiration, incredible memories, to celebrate, or to have the most fun you’ve ever had, everyone has their perfect destination just waiting to be discovered. Beyond exclusive destinations, we create destinations exclusively for you.'
    ]
  },
  {
    label: 'How?',
    text: [
      'It all starts with the Teleport Dream Discovery Interview. From this, we discover the type of Teleport that you’d enjoy and benefit from most. Then, like in film and theatre, a script is created, locations are found, actors are cast, magic tools are employed to create a personalised adventure.',
      'During your chosen travel or event dates, fact can become fiction and then reality is redefined around you. We ensure that our clients always get what they want, but never in the way they expect it.'
    ]
  },
  {
    label: 'Who can teleport?',
    text: [
      'You can Teleport solo, with family, friends or with colleagues for an extraordinary corporate event.',
      'A shared Teleport can create powerful bonds through shared experience. Or you can Teleport others for an unparalleled luxury gift.'
    ]
  },
  {
    label: 'Why travel with Teleport?',
    text: [
      'We create destinations and experiences that are unique to you. We offer what cannot be found anywhere else.',
      'If you’re looking for a transformational experience. The ultimate gift for yourself or others. Celebrating one of life’s milestones such as a marriage or a birthday. Creating defining moments with friends or family. Looking for the height of adventure in safe and controlled conditions. Or you want to discover new ideas and passion or hold a corporate event like no other. Teleport can take you there.'
    ]
  },
  {
    label: 'What will I bring back from my journey?',
    text: [
      'We are changed by the journeys we make and the experiences we have. Travelling with Teleport is not simply a question of ‘where do you want to go’, but also ‘how would you like to return?’ We create transformational experiences for our clients that are not only fun but truly valuable.'
    ]
  },
  {
    label: 'How much does it cost?',
    text: [
      'This is not a luxury vacation or event, it is an experiential luxury journey to a customised reality built entirely around you and your wants and wishes. As each Teleport is absolutely unique, we don’t have a set price. But we offer the following examples:',
      'Two days of a ‘shared teleport’ with four others would start at around €35’000 p.p.',
      'A Teleport event or a trip for a couple or a family will be in the region of €180’000.',
      'A year-long program with a week or two weeks of full immersion would start at around €500,000.'
    ]
  },
  {
    label: 'Is there a film like this?',
    text: [
      'Teleport is inspired by films like The Game, La Belle Epoque and The Truman Show. But our story is always a story built around you. Your wants, dreams, desires, and wishes. Unique to you and not \'like\' anything else.'
    ]
  }
]

export const journeys = {
  1: 'Just married, again.',
  2: 'Extraordinary Ordinary.',
  3: 'The king is dead. Long live the king.',
  4: 'Yesterday, today, tomorrow, and always.'
}

export const journeysSubtitle = {
  1: 'An unusual gift to celebrate 25 years of marriage.',
  2: 'What living a ‘different life’ might have been like.',
  3: 'To navigate change, embrace new opportunities and visualise the future.',
  4: 'A surprise gift from the sister of the bride-to-be.'
}

export const journeysItems = [
  {
    title: 'Just married',
    subTitle: 'Again',
    texts: [
      'A client was looking for an unusual gift to celebrate 25 years of marriage. But to be a truly valuable journey, it shouldn’t just celebrate the years passed but inspire years to come.',
      'Our unique Teleport Discovery Process helped us create a powerful journey of time travel. The opportunity to return to their young crazy love and then onwards to the places, the people, and the most important moments shared.  To experience who they were to each other, who they became for each other, and inspire who they can be for each other.',
      'Ten locations from the past, two helicopter rides to the future, a cast of fifty-six, a tandem bike, a black cat called Toby and a bottle of very, very, rare wine later, and our clients absolutely loved their Teleport. And yes, they continue to love each other.',
      'A present of the past and the future, courtesy of Teleport.'
    ]
  },
  {
    title: 'Extraordinary',
    subTitle: 'Ordinary',
    texts: [
      'A very successful client came to us with a very unusual wish. He had dedicated his life to entrepreneurism and had built an impressive portfolio of business interests and a certain notoriety. But in doing so, he had made sacrifices. He had no regrets but was desperately curious to know what living a ‘different life’ might have been like.',
      'The Teleport Discovery Process revealed all aspects of ‘different life’ that he’d felt he might have missed out on. So we created a home, a family, a job, and the anonymity he needed to experience them.',
      'Three days of a life he had never lived.  A job on a construction site, five-aside football in the park, a wedding attended,  a BBQ in the park and a 3 am solo in a karaoke bar.  What many would describe as an ordinary life ,was experienced as an extraordinary life.',
      'An opportunity to learn about yourself from the unusual position of being someone else, courtesy of Teleport.'
    ]
  },
  {
    title: 'The king is dead. Long live',
    subTitle: 'the king',
    texts: [
      'A client wanted to help his employees navigate change, embrace new opportunities and visualise the future.',
      'The Teleport Discovery Process was shared with key employees, and we learnt that success created insecurity. Fast growth was creating fast change, which allowed little time for adaptation.',
      'Their Teleport took them to a hotel on the very edge of their comfort zone, and the team was confronted by forty-eight hours of the truly weird and the truly wonderful. Through the laughs and the gasps, they learned the skills and power of rapid change as individuals and as a team. They also managed to kill their boss three times and then bring him back from the grave. That’s an excellent business result by anyone\'s standards.',
      'New thrills and new skills, courtesy of Teleport.'
    ]
  },
  {
    title: 'Yesterday, today, tomorrow,',
    subTitle: 'and always',
    texts: [
      'A hen party is often seen as the ‘last weekend of your old life’ before your new ‘married life’ begins. Quite a scary prospect if your life has been as amazing as our clients had been. A surprise gift from the sister of the bride-to-be was for Teleport to rewrite the ‘hen party’ narrative for four friends – to celebrate not the end of an old life but the beginning of a new chapter in the story of an incredible life.',
      'Through the Teleport Discovery Process, we learnt that our client was torn between the idea of an ‘old life’ and a ‘new life’. Her Teleport would need to build a bridge between the two, and where better than the city of bridges, Amsterdam?',
      'For ten hours, we redesigned our client\'s reality and turned the city into a beautiful playground of impossible coincidences, with fortune cookies that actually know the future, an art gallery that made our client the star of the show, an impromptu dance class that played ‘her tune’, and a fashion designer’s catwalk that led to the door of a mystic who helped our client find the answers to all of her questions about the past and the future.',
      'What started as a classic ‘hen weekend’ became a journey through hopes and fears, dreams and new realities. A truly moving experience that, through tears of joy, saw a celebration of friendship through t that has always been and will always be, courtesy of Teleport.'
    ]
  }
]

export const brandStory = [
  'This dream was shared by other like-minded founders and has become a reality, ‘teleporting’ people into uniquely designed worlds that can transform them, help them overcome obstacles, and turn dreams into reality.',
  'It all started with our founder’s childhood dream of creating a teleportation machine that would transport him (and his friends) to other worlds. Worlds full of wonder, excitement, purpose and adventure.',
  'Our eclectic team is created from the very best talents found in movie & series production, theatre, magic, music,  art and psychology: people that believe in the power of storytelling as a means to experiencing life to the fullest.',
  'Creating dreams for our clients is what excites us. We create the highest form of meaningful entertainment in the world.'
]

const currentYear = new Date().getFullYear()
const formYears = []
for (let i = 0; i <= 3; i++) {
  formYears.push(currentYear + i)
}

export const formFields = {
  month: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  years: [...formYears],
  days: [
    '1 day',
    '2 days',
    '3+ days',
    '7+ days',
    '14+ days',
    '21+ days'
  ],
  peoples: [
    '1',
    '2',
    '3+',
    '10+',
    '25+'
  ],
  where: [
    'I’m not sure. Surprise me',
    'An adventure of a lifetime',
    'To experience something completely new',
    'A marriage proposal or honeymoon',
    'I want to make a dream come true',
    'Transformational Journey',
    'New ideas and passion discovery',
    'I’d like to time travel (past or future)',
    'I want to be inspired',
    'A very special gift',
    'A celebration',
    'A corporate event',
    'I have an idea, but it’s not on your list',
    'I want to ride a unicorn through space and time'
  ]
}

export const yourJourney = [
  'During this time, you may feel some unexpected emotions. A nervous excitement, a distracting curiosity, and/or an abstract feeling that something is about to change. This is perfectly normal.',
  'Until now, there was a clear dividing line between fact and fiction. That line is now fading, and your mind is adjusting to this shift in reality. The adjustment is essential for you to accept that, what was previously impossible, is now entirely possible.',
  'Your journey has begun.'
]

export const manifesto = [
  [
    `<p>If you could do anything, then what would you do?</p>
    <p>If a dream that you’ve had could come true.</p>
    <p>If you could see anything, then what would you see?</p>
    <p>And if you could be anyone,  who would you be?</p>`
  ],
  [
    `<p>If anything could be known, then what would you know?</p>
    <p>If you could go anywhere, then where would you go?</p>
    <p>If the past could be present or the future right now, to live any time, what time would be now?</p>`
  ],
  [
    `<p>If fact and fiction could somehow blend, then a fantasy could be any reality you intend.</p>
    <p>If nothing was impossible, then imagine what could be.</p>
    <p>If you just say the words,</p>
    <p>“Teleport me”</p>`
  ]
]

export const detailsCard = [
  {
    title: 'price',
    subtitle: '$125k - >$500k',
    text: 'Teleport redefines reality around your dreams, wants and needs to create an unforgettable and life-changing experience.'
  },
  {
    title: 'how many travellers',
    subtitle: '1 – 10',
    text: 'Experience Teleport solo, with friends, family or with colleagues.'
  },
  {
    title: 'how long',
    subtitle: '1-14 days',
    text: 'A Teleport can be between 1 and 14 days, but the experience often starts weeks in advance of the actual journey.'
  },
  {
    title: 'destinations',
    subtitle: 'Worldwide',
    text: 'Experience the world as you believe it could and should be.'
  }
]
