<template>
  <component
    :is="tag"
    :class="[{ 'main-title__animated': isAnimate },
      tag === 'h2' ? 'heading-xs' : 'main-title'
    ]"
  >
    Q&A
  </component>
  <p v-if="version === 'long'" class="uniq-text">We don’t tell you a story, we make you the story.</p>
  <AppAccordion class="qa__accordion">
    <AppAccordionItem
      class="qa__accordion__item"
      :class="{
        'qa__accordion__item-animated': isAnimate,
        'accordion__item--mid': version === 'long'
      }"
      v-for="(qa, index) in (version === 'short' ? qaList.slice(0, 3) : qaList)"
      :key="index"
      :label="qa.label"
      page="main"
    >
      <template #accordion-content>
        <p
          v-for="(text, index) in qa.text"
          :key="index"
          class="text-s"
        >
          {{ text }}
        </p>
      </template>
    </AppAccordionItem>
  </AppAccordion>
  <router-link v-if="version === 'short'" to="/qa" class="qa__link-more btn btn-brand"
              :class="{'qa__link-more-animated': isAnimate}"
  >Do you want to know more?</router-link>
  <AppContact v-if="version !== 'short'" class="qa__contact" :isAnimate="isAnimate"/>
</template>

<script>
import { qaList } from '@/composition/useState'
import AppAccordion from '@/components/UI/AppAccordion.vue'
import AppAccordionItem from '@/components/UI/AppAccordionItem.vue'
import AppContact from '@/components/UI/AppContact.vue'

export default {
  name: 'AppQA',
  components: {
    AppContact,
    AppAccordion,
    AppAccordionItem
  },

  props: {
    isAnimate: {
      type: Boolean,
      default: false,
      required: false
    },
    tag: {
      type: String,
      default: 'h1',
      required: true,
      validator: prop => ['h1', 'h2'].includes(prop)
    },
    version: {
      type: String,
      default: 'long',
      required: false,
      validator: prop => ['long', 'short'].includes(prop)
    }
  },

  setup () {
    return {
      qaList
    }
  },

  mounted () {
    if (!this.isAnimate) {
      return
    }
    const items = document.querySelectorAll('.qa__accordion__item-animated')
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const title = document.querySelector('.main-title__animated')
          const btn = document.querySelector('.qa__link-more-animated')
          title.classList.add('main-title__animated--active')
          btn.classList.add('qa__link-more-animated--active')
          items.forEach(i => i.classList.add('qa__accordion__item-animated--active'))
        }
      })
    })
    observer?.observe(document.querySelector('.qa__accordion'))
  }
}
</script>

<style scoped>
.heading-xs {
  line-height: 130%;
}

.main-title {
  justify-self: self-start;
}

.main-title__animated {
  opacity: 0;
}

.main-title__animated--active {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.qa__accordion {
  width: 100%;
}

.qa__link-more {
  margin-left: auto;
  margin-right: auto;
}

.qa__link-more-animated {
  opacity: 0;
}

.qa__link-more-animated--active {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 3.5s;
}

.qa__accordion__item-animated {
  opacity: 0;
}

.qa__accordion__item-animated--active {
  animation-name: block-animation;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.qa__accordion__item-animated--active:nth-child(2) {
  animation-delay: 1.5s;
}

.qa__accordion__item-animated--active:nth-child(3) {
  animation-delay: 2.5s;
}

.qa__animate {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.qa__animate > .main-title,
.qa__animate > *[class*='heading'] {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.text-s {
  font-family: 'Roboto', sans-serif;
  opacity: .6;
}

@media (min-width: 1025px) {
  .qa__link-more {
    height: 40px;
    width: 300px;
    margin-top: 60px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .qa__link-more {
    height: 30px;
    width: 225px;
    margin-top: 45px;
  }
}

@media (max-width: 430px) {
  .qa__link-more {
    height: 32px;
    width: 240px;
    margin-top: 40px;
  }
}

@media (min-width: 799px) {
  .uniq-text {
    margin-bottom: 75px;
  }
}

@media (max-width: 798px) and (min-width: 501px){
  .uniq-text {
    margin-bottom: 40px;
  }
}

@media (min-width: 501px) {
  .main-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .main-title  {
    margin: 0;
  }
}

@keyframes qa-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes qa-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes qa-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes block-animation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes block-animation {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

@-moz-keyframes block-animation {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
</style>
