<template>
  <div class="container">
    <div class="text">
      <div>Go</div>
      <div>where</div>
      <div>anything</div>
      <div>is <span> possible</span></div>
      <div>teleport</div>
    </div>
    <transition name="fade">
      <button v-show="isShowBtn" class="btn" aria-label="Skip intro" @click="skipIntro"></button>
    </transition>
    <div class="container-video two">
      <video src="../../assets/media/video-1.mp4" type="video/mp4" autoplay muted playsinline loop></video>
      <div class="angle">
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
      </div>
    </div>

    <div class="container-text three">
      <div class="hexagon"></div>
      <div class="text-container">
        <div class="text-two">
          Teleport is the ultimate<br>luxury travel company
        </div>
      </div>
    </div>

    <div class="container-four">
      <video src="../../assets/media/video-2.mp4" type="video/mp4" autoplay muted playsinline loop></video>
    </div>

    <div class="container-text five">
      <div class="hexagon"></div>
      <div class="text-container">
        <div class="text-two">
          Specializing in destinations<br>not found on any maps
        </div>
      </div>
    </div>

    <div class="container-video six">
      <video src="../../assets/media/video-3.mp4" type="video/mp4" autoplay muted playsinline loop></video>
      <div class="angle">
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
        <div class="fItem"></div>
      </div>
    </div>

    <div class="container-text seven">
      <div class="hexagon"></div>
      <div class="text-container">
        <div class="text-two">
          Destinations that are<br>
          built around your wants,<br>wishes and dreams.
        </div>
      </div>
    </div>

    <div class="container-four last">
      <video src="../../assets/media/video-4.mp4" type="video/mp4" autoplay muted playsinline loop></video>
    </div>

    <div class="container-text eight">
      <div class="hexagon"></div>
      <div class="text-container">
        <AppHomeScreen/>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref } from 'vue'
import AppHomeScreen from '@/components/AppHomeScreen.vue'
import { skipIntro } from '@/composition/useActions'

export default {
  name: 'AppIntro',
  components: { AppHomeScreen },
  setup () {
    const isShowBtn = ref(true)
    const hideBtn = setTimeout(() => {
      isShowBtn.value = false
    }, 30500)
    const hideIntro = setTimeout(() => skipIntro(), 31500)
    onBeforeUnmount(() => {
      clearTimeout(hideBtn)
      clearTimeout(hideIntro)
    })
    return {
      isShowBtn,
      skipIntro
    }
  }
}
</script>

<style scoped>
.container {
  background: #000;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

button {
  position: absolute;
  z-index: 20;
  border-radius: 50%;
  border: unset;
  background: rgba(0,0,0, .2) url(../../assets/icons/btn-down.svg)no-repeat 50% 60%/47%;
  bottom: 100px;
}

button:hover {
  border: unset;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  line-height: 159px;
  animation-name: hide;
  animation-delay: 11s;
  animation-fill-mode: forwards;
}

.text div {
  animation-name: fadeIn;
  height: 0;
  position: absolute;
  display: flex;
  align-items: center;
  opacity: 0;
}

.text div:nth-child(1) {
  opacity: 0;
  animation-duration: 1.9s;
}

.text div:nth-child(2) {
  opacity: 0;
  animation-delay: 2s;
  animation-duration: 1.9s;
}

.text div:nth-child(3) {
  line-height: 159px;
  text-transform: uppercase;
  opacity: 0;
  animation-delay: 4s;
  animation-duration: .9s;
}

.text div:nth-child(4) {
  opacity: 0;
  animation-delay: 5s;
  animation-duration: 2.9s;
}

.text div>span {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-left: 17px;
}

.text div > span::after {
  content: '';
  position: absolute;
  top: 0;
  right: -158px;
  bottom: 0;
  left: -90px;
  animation-name: spanT;
  animation-delay: 6s;
  animation-duration: 1.9s;
  animation-fill-mode: forwards;
  background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(0,0,0,1) 100px);
}

.text div:nth-child(5) {
  font-family: 'ABC Diatype';
  line-height: 77px;
  opacity: 0;
  animation-delay: 8s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  text-transform: uppercase;
}

.container-video {
  position: absolute;
  background: transparent;
  animation-fill-mode: forwards;
  animation-name: show;
  overflow: hidden;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 0;
}

.angle {
  width: 524px;
  height: 752px;
  position: absolute;
  background: transparent;
  animation-duration: 12s;
  animation-fill-mode: forwards;
  animation-name: figure;
  z-index: -1;
  box-shadow: 0 0 0px 1920px rgba(0, 0, 0, 1)
}

.container-video>video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
}

.fItem:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 0px solid transparent;
  border-right: 216px solid transparent;
  border-top: 119px solid black;
}

.fItem:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 102px solid black;
  border-bottom: 64px solid transparent;
  border-right: 62px solid transparent;
  height: 419px;
}

.fItem:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left: 0px solid transparent;
  border-right: 308px solid transparent;
  border-bottom: 207px solid black;
}

.fItem:nth-child(4) {
  position: absolute;
  top: 0;
  right: 0;
  border-right: 0px solid transparent;
  border-left: 308px solid transparent;
  border-top: 207px solid black;
}

.fItem:nth-child(5) {
  position: absolute;
  bottom: 0;
  right: 0;
  border-right: 102px solid black;
  border-top: 64px solid transparent;
  border-left: 62px solid transparent;
  height: 419px;
}

.fItem:nth-child(6) {
  position: absolute;
  bottom: 0;
  right: 0;
  border-right: 0px solid transparent;
  border-left: 216px solid transparent;
  border-bottom: 119px solid black;
}

.two {
  animation-delay: 9s;
  animation-duration: 1.5s;
  overflow: hidden;
}

.two>.angle {
  animation-delay: 9s;
}

.six {
  animation-delay: 21s;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  overflow: hidden;
  z-index: 3;
}

.six>.angle {
  animation-delay: 21s;
}

.container-text {
  position: absolute;
  background: transparent;
  animation-fill-mode: forwards;
  animation-name: show;
  overflow: hidden;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-text .text-two {
  text-align: center;
  line-height: 57px;
  background: transparent;
  color: white;
  white-space: nowrap;
  font-family: Office Times Sharp;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.hexagon {
  margin: 20px 0;
  width: 100px;
  height: 55px;
  background: black;
  position: absolute;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: hexagon;
  animation-timing-function: ease-in;
  z-index: -1;
}

.hexagon:before,
.hexagon:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}
.hexagon:before {
  top: -25px;
  border-bottom: 25px solid black;
}
.hexagon:after {
  bottom: -25px;
  border-top: 25px solid black;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  width: 0;
  overflow: hidden;
  animation-duration: 4s;
  animation-delay: 11s;
  animation-fill-mode: forwards;
  animation-name: textCont;
  z-index: 4;
}

.three {
  animation-delay: 11s;
}

.three>.hexagon {
  animation-delay: 11s;
}

.three>.text-container {
  animation-delay: 11.2s;
}

.five {
  animation-delay: 17s;
}

.five>.hexagon {
  animation-delay: 17s;
}

.five>.text-container {
  animation-delay: 17.2s;
}

.seven {
  animation-delay: 23s;
}

.seven>.hexagon {
  animation-delay: 23s;
}

.seven>.text-container {
  animation-delay: 23.2s;
}

.container-four {
  position: absolute;
  background: transparent;
  animation-delay: 15s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: restangle;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 0;
}

.container-four video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.last {
  animation-delay: 27s;
}

.eight {
  animation-delay: 29s
}

.eight>.hexagon {
  animation-delay: 29s;
  animation-duration: 2.5s;
}

.eight>.text-container {
  animation-delay: 29.3s;
  animation-duration: 2.5s;
  max-width: 100vw;
}

.eight>.text-container>.home {
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

@keyframes restangle {
  0% {
    height: 0;
    width: 0;
  }

  100% {
    height: 450vw;
    width: 200vw;
  }
}

@keyframes textCont {
  0% {
    height: 0;
    width: 0;
  }

  100% {
    height: 100vh;
    width: 100%;
  }
}

@keyframes spanT {
  0% {
    transform: translateX(-80px);
  }

  100% {
    transform: translateX(260px);
  }
}

@keyframes spanTDesktop {
  0% {
    transform: translateX(-80px);
  }

  100% {
    transform: translateX(320px);
  }
}

@keyframes hide {
  0% {
    z-index: 2;
  }

  100% {
    z-index: -1;
  }
}

@keyframes show {
  0% {
    z-index: 2;
  }

  100% {
    width: 100vh;
    height: 100vh;
    z-index: 2;
  }
}

@keyframes showDesktop {
  0% {
    z-index: 2;
  }

  100% {
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
}

@keyframes hexagon {
  0% {
    z-index: 2;
    transform: scale(0);
  }

  100% {
    z-index: 2;
    transform: scale(50);
  }
}

@keyframes figure {
  0% {
    z-index: 2;
    transform: scale(0);
  }

  100% {
    z-index: 2;
    transform: scale(20);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  99.9% {
    opacity: 1;
  }

  100% {
    z-index: 0;
  }
}

@keyframes fadeIn {
  0% {
    position: relative;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1920px) {
  .container-text .text-two,
  .text {
    font-size: 80px;
  }
  .text div:nth-child(3) {
    font-size: 95px;
  }
}

@media (min-width: 1024px) {
  .container-text, .container-video {
    animation-name: showDesktop;
  }

  .text div > span::after {
    animation-name: spanTDesktop;
  }
}

@media (max-width: 1919px) {
  .container-text .text-two,
  .text {
    font-size: calc(24px + (56 + 56 * 0.7) * ((100vw - 375px) / 2624));
  }

  .text div:nth-child(3) {
    font-size: calc(24px + (71 + 71 * 0.7) * ((100vw - 375px) / 2624));
  }
}

@media (min-width: 501px) {
  .text div:nth-child(5) {
    font-size: 36px;
  }

  button {
    height: 76px;
    width: 76px;
  }
}

@media (max-width: 500px) {
  .text div:nth-child(5) {
    font-size: 24px;
  }

  button {
    height: 46px;
    width: 46px;
  }
}
</style>
