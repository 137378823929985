<template>
  <footer class="footer">
    <div class="footer__content">
      <AppMailLink class="teleport-link"/>

      <div class="footer__links">
        <div
          v-for="(linksBlock, key) in linksFooter"
          :key="key"
        >
          <h3>{{ key }}</h3>
          <ul>
            <li
              v-for="link in linksBlock"
              :key="link.href"
            >
              <component
                :is="link.tag"
                :to="link.href"
                class="footer__link"
                :class="{'btn-link': link.tag === 'button'}"
                @click.prevent="defineAction(link.text)"
              >
                {{ link.text }}
              </component>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { isShowModal, linksFooter } from '@/composition/useState.js'
import AppLogo from '@/components/UI/AppLogo.vue'
import AppMailLink from '@/components/UI/AppMailLink.vue'
import router from '@/router'
export default {
  name: 'AppFooter',
  components: {
    AppLogo,
    AppMailLink
  },
  setup () {
    const defineAction = (controlName) => {
      if (controlName === 'Manifesto') {
        isShowModal.value = true
      } else if (controlName === 'Testimonials') {
        goToHash('testimonials')
      }
    }

    const goToHash = (key) => {
      router.push('/')
      setTimeout(() => {
        const blockTop = document.getElementById('testemonials').getBoundingClientRect().top
        window.scrollTo(0, blockTop)
      }, 1)
    }

    return {
      defineAction,
      linksFooter
    }
  }
}
</script>

<style scoped>
footer {
  height: fit-content;
  width: 100%;
  background-color: var(--neutral900);
}

.footer__content {
  max-width: var(--size-5);
  margin: 0 auto;
  padding: 0 var(--padding-s);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__links {
  width: 100%;
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  border-top: 1px solid var(--neutral800);
}

h3 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
}

h3,
li {
  text-align: left;
}

h3,
.footer__link {
  font-family: 'Roboto', sans-serif;
  color: var(--white);
}

.footer__link {
  opacity: .6;
  font-style: normal;
  font-weight: 400;
}

@media (min-width: 1025px) {
  footer {
    padding: 60px 0 22px;
  }

  .footer__content {
    gap: 55px;
  }

  .footer__links {
    padding-top: 26px;
  }

  .teleport-link {
    font-size: 50px;
  }

  h3 {
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 250%;
    letter-spacing: 1px;
  }

  .footer__link {
    font-size: 16px;
    line-height: 187.5%;
    letter-spacing: 1px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  footer {
    padding: var(--size-7) 0 23.5px;
  }

  .footer__content {
    gap: 41px;
  }

  .footer__links {
    padding-top: 19.5px;
  }

  .teleport-link {
    font-size: 37.5px;
  }

  h3 {
    margin-bottom: 1px;
    font-size: 9px;
    line-height: 250%;
    letter-spacing: 0.75px;
  }

  .footer__link {
    font-size: 12px;
    line-height: 187.5%;
    letter-spacing: 0.75px;
  }
}

@media (min-width: 431px) {
  .footer__links {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__links > *:last-child {
    justify-self: flex-end;
  }
}

@media (max-width: 430px) {
  footer {
    padding: var(--size-6) 0 53px;
  }

  .footer__content {
    gap: var(--size-8);
  }

  .footer__links {
    padding-top: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .teleport-link {
    font-size: 30px;
  }

  h3 {
    margin-bottom: -6px;
    font-size: 10px;
    line-height: 300%;
    letter-spacing: 1px;
  }

  .footer__link {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
</style>
