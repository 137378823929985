<template>
  <section class="testimonials">
    <div class="testimonials__content">
      <h2 class="heading-xs">Testimonials</h2>
      <div class="slider__wrapper">
        <swiper
          :slidesPerView="'auto'"
          :rewind="true"
          :grabCursor="true"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
        >
          <swiper-slide
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <div class="slide__item">
              <img v-if="testimonial.img" :src="require(`../assets/images/testimonials/${testimonial.img}`)" class="slide__item-img">
              <img v-else src="../assets/icons/user.svg" class="slide__item-img">
              <h3 class="slide__item-title">
                <div class="slide__item__shadow-author"></div>
                <cite>{{ testimonial.author }}</cite>
                <small class="text-s">{{ testimonial.profession }}</small>
              </h3>
              <blockquote class="slide__item-text">
                <div class="slide__item__shadow-quote"></div>
                <div class="slide__item__shadow-text"></div>
                <div class="slide__item__shadow-text"></div>
                <div class="slide__item__shadow-text"></div>
                <span class="quote"></span>
                <p class="text-s">{{ testimonial.text }}</p>
              </blockquote>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'
import 'swiper/css'
import { testimonials } from '@/composition/useState'
import { required } from '@vuelidate/validators'
export default {
  name: 'AppTestimonials',
  computed: {
    required () {
      return required
    }
  },
  components: { Swiper, SwiperSlide },

  setup () {
    return { testimonials, modules: [Pagination] }
  },

  mounted () {
    const items = document.querySelectorAll('.slide__item')
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slider__wrapper--animation')
          items.forEach(i => i.classList.add('slide__item--animate'))
        }
      })
    })
    observer?.observe(document.querySelector('.slider__wrapper'))
  }
}
</script>

<style scoped>
@import '@/assets/css/swiper-pagination.css';

* {
  text-align: center;
  --quote-shadow-height: 40px;
}

.testimonials {
  padding: var(--padding-xl) 0 0;
  background-color: var(--black);
  overflow: hidden;
}

.testimonials__content {
  max-width: var(--size-5);
  margin: 0 auto;
  padding: 0 var(--padding-s);
}

.heading-xs {
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Skema Pro Display', serif;
}

.swiper-slide,
swiper-slide {
  height: auto;
  width: auto;
}

.swiper {
  overflow: visible;
}

.slide__item-title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.slide__item-text {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-style: italic;
  position: relative;
  overflow: hidden;
}

.slide__item-title .text-s,
.slide__item-text .text-s {
  position: relative;
  z-index: 1;
  opacity: .6;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

cite {
  font-family: 'Skema Pro Display', serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.quote {
  display: block;
  color: var(--brand);
}

.quote:after {
  content: '”';
  text-align: center;
  font-family: 'Skema Pro Display', serif;
  font-weight: 600;
  line-height: 130%;
}

.slide__item {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  background: var(--neutral900);
}

.slide__item-img {
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  background-image: radial-gradient(transparent 35px, var(--brand) 35px);
}

.slide__item__shadow-author {
  position: absolute;
  height: 100%;
  background: var(--neutral900);
  top: 0;
  right: 0;
  left: 0;
}

.slide__item__shadow-quote,
.slide__item__shadow-text {
  position: absolute;
  background: var(--neutral900);
  right: 0;
  left: 0;
  z-index: 5;
}

.slide__item__shadow-quote {
  height: var(--quote-shadow-height);
}

.slide__item__shadow-text:nth-child(2) {
  top: var(--quote-shadow-height);
}

.slide__item__shadow-text {
  height: var(--text-shadow-height);
}

.slide__item__shadow-text:nth-child(3) {
  top: calc(var(--text-shadow-height) + var(--quote-shadow-height));
}

.slide__item__shadow-text:nth-child(4) {
  top: calc(var(--text-shadow-height) * 2 + var(--quote-shadow-height));
}

/*Анимация*/

.slide__item--animate .slide__item__shadow-quote,
.slide__item--animate .slide__item__shadow-text {
  animation-name: text-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.slide__item--animate .slide__item__shadow-quote {
  animation-delay: .25s;
}

.slide__item--animate .slide__item__shadow-text:nth-child(2) {
  animation-delay: .5s;
}

.slide__item--animate .slide__item__shadow-text:nth-child(3) {
  animation-delay: 1.5s;
}

.slide__item--animate .slide__item__shadow-text:nth-child(4) {
  animation-delay: 2.5s;
}

.slide__item--animate .slide__item__shadow-author {
  animation-name: text-animation;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.slide__item--animate .slide__item-title {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.slide__item--animate .slide__item-text {
  animation-name: block-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

@media (min-width: 1025px) {
  * {
    --text-shadow-height: 115px;
  }

  .testimonials {
    padding-bottom: 168px;
  }

  .heading-xs {
    margin-bottom: 62px;
  }

  .slide__item {
    min-width: 350px;
    width: 350px;
    min-height: 520px;
    padding: 30px;
  }

  .slide__item-title {
    margin: 30px 0 10px;
    gap: 5px;
  }

  .slide__item-img {
    width: 90px;
    height: 90px;
  }

  .slide__item-text {
    gap: 20px;
  }

  .quote {
    height: 30px;
  }

  .quote:after {
    font-size: 60px;
  }

  cite {
    font-size: 20px;
  }

  .swiper-slide + .swiper-slide,
  swiper-slide + swiper-slide {
    margin-left: 25px;
  }
}

@media  (max-width: 1024px) and (min-width: 431px) {
  * {
    --text-shadow-height: 78px;
  }

  .testimonials {
    padding-bottom: 141.5px;
  }

  .heading-xs {
    margin-bottom: 46.5px;
  }

  .slide__item {
    min-width: 262.5px;
    width: 262.5px;
    min-height: 390px;
    padding: 22.5px;
  }

  .slide__item-title {
    margin: 22.5px 0 7px;
    gap: 4px;
  }

  .slide__item-img {
    width: 67.5px;
    height: 67.5px;
  }

  .slide__item-text {
    gap: 15px;
  }

  .quote {
    height: 22.5px;
  }

  .quote:after {
    font-size: 45px;
  }

  cite {
    font-size: 15px;
  }

  .swiper-slide + .swiper-slide,
  swiper-slide + swiper-slide {
    margin-left: 18px;
  }
}

@media (max-width: 430px) {
  * {
    --text-shadow-height: 74px;
  }

  .testimonials {
    padding-bottom: 120px;
  }

  .heading-xs {
    margin-bottom: 44px;
  }

  .slide__item {
    min-width: 260px;
    width: 260px;
    min-height: 287px;
    padding: 22px;
  }

  .slide__item-title {
    margin: 22px 0 7px;
    gap: 3px;
  }

  .slide__item-img {
    width: 67px;
    height: 67px;
  }

  .slide__item-text {
    gap: 14.9px;
  }

  .quote {
    height: 22px;
  }

  .quote:after {
    font-size: 44.6px;
  }

  cite {
    font-size: 15px;
  }

  .swiper-slide + .swiper-slide,
  swiper-slide + swiper-slide {
    margin-left: 15px;
  }
}

@keyframes text-animation {
  0% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes block-animation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes test {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
</style>
