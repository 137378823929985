import { useHead } from '@vueuse/head'
import { metaTagsContent } from '@/composition/useState'
export const useMetaTag = (url, pageName) => {
  const metaTagsName = ['title', 'description', 'og:title', 'og:description', 'og:url', 'twitter:title', 'twitter:description', 'twitter:url']
  const metaData = []
  const title = metaTagsContent[pageName].title
  const descr = metaTagsContent[pageName].description
  metaTagsName.forEach((el) => {
    if (el === 'title' || el === 'description') {
      metaData.push({
        name: el,
        content: el === 'title' ? title : descr
      })
    } else {
      metaData.push({
        property: el,
        content: el.includes('title') ? title : el.includes('description') ? descr : url
      })
    }
  })
  return useHead({
    title: metaTagsContent[pageName].title,
    meta: [...metaData]
  })
}
