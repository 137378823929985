<template>
  <a href="mailto:please@teleportme.nl" class="mail-link" target="_blank">please@teleportme.nl</a>
</template>

<script>
export default {
  name: 'AppMailLink'
}
</script>

<style scoped>
.mail-link {
  color: var(--brand);
  font-family: 'Skema Pro Display', serif;
  font-style: italic;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
}
</style>
