<template>
  <transition name="modal">
    <div v-show="isShowModal" class="modal__mask">
      <div class="modal__wrapper">
        <div class="card--lg">
          <div :class="setClass"></div>
          <div
            class="modal__container"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal__header">
             <h2 id="modalTitle" class="main-title">Manifesto</h2>
              <button
                type="button"
                class="btn btn-close--rounded"
                @click="isShowModal = !isShowModal"
                aria-label="Close modal"
              ></button>
            </header>
            <AppTypedCard
              v-if="currentCardIndex === 0"
              :text="manifesto[0]"
              :typeSpeed="typeSpeed"
            />
            <AppTypedCard
              v-else-if="currentCardIndex === 1"
              :text="manifesto[1]"
              :typeSpeed="typeSpeed"
            />
            <AppTypedCard
              v-else-if="currentCardIndex === 2"
              :text="manifesto[2]"
              :typeSpeed="typeSpeed"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { watch, ref, computed } from 'vue'
import { isShowModal, manifesto } from '@/composition/useState'
import AppTypedCard from '@/components/UI/AppTypedCard.vue'
import { toggleHtmlBlock } from '@/composition/useActions'
export default {
  name: 'AppModal',
  components: { AppTypedCard },
  setup () {
    const currentText = ref(1)
    const currentCardIndex = ref(null)
    const typeSpeed = 60
    const delay = computed(() => typeSpeed * manifesto[currentCardIndex.value || 0][0].length)
    let setText
    watch(isShowModal, (value) => {
      if (value) {
        init()
      } else {
        reset()
      }
    })

    const init = () => {
      currentCardIndex.value = 0
      toggleHtmlBlock('add')
      setText = setTimeout(() => changeText(), delay.value * 1.5)
    }

    const reset = () => {
      clearTimeout(setText)
      currentCardIndex.value = null
      currentText.value = 1
      toggleHtmlBlock('remove')
    }

    const changeText = () => {
      if (currentText.value < manifesto.length) {
        currentText.value++
        currentCardIndex.value++
        clearTimeout(setText)
        setText = setTimeout(() => changeText(), delay.value * 1.5)
      } else {
        isShowModal.value = false
      }
    }

    const setClass = computed(() => currentText.value % 2 === 0 ? 'modal__gradient--lg' : 'modal__gradient--sm')

    return {
      currentCardIndex,
      setClass,
      typeSpeed,
      isShowModal,
      manifesto
    }
  }
}
</script>

<style scoped>
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-grey);
  display: flex;
  transition: opacity 0.3s ease;
}

header,
article {
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: start;
}

article {
  align-self: center;
  font-weight: 400;
  text-align: center;
  justify-self: center;
}

.modal__wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card--lg {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--black);
  overflow: hidden;
}

.modal__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  transition: all 0.3s ease;
  overflow: scroll;
}

.modal__container::-webkit-scrollbar {
  display: none;
}

.modal__container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal__gradient--sm,
.modal__gradient--lg {
  position: absolute;
  left: 50%;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.modal__gradient--sm {
  height: 70%;
  bottom: -35%;
  background-image: radial-gradient(ellipse, #0E409C 0, transparent 70%);
  opacity: 0.5;
}

.modal__gradient--lg {
  height: 200%;
  width: 300%;
  bottom: -100%;
  background-image: radial-gradient(ellipse, #0E409C 0, transparent 70%);
  opacity: 0.7;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

@media (min-width: 1920px) {
  .modal__wrapper {
    margin: 44px 55px;
  }

  article {
    max-width: 900px;
    font-size: 60px;
    line-height: 65px;
  }

  header {
    padding: 23px 26px 0 27px;
  }
}

@media (max-width: 1919px)  and (min-width: 799px) {
  .modal__wrapper {
    margin: 44px 55px;
  }

  article {
    font-size: 44px;
    line-height: 47px;
    max-width: 772px;
    padding: 0 36px;
  }

  header {
    padding: 17px 19px 0 20px;
  }
}

@media (min-width: 799px) {
  .modal__gradient--sm {
    width: 200%;
  }
}

@media (max-width: 798px)  and (min-width: 501px) {
  .modal__wrapper {
    margin: 42px 33px 70px;
  }

  article {
    padding: 50px;
    font-size: 32px;
    line-height: 40px;
  }

  header {
    padding: 20px 18px 0 27px;
  }
}

@media (max-width: 798px) {
  .modal__gradient--sm {
    width: 85%;
  }

  article {
    padding: 0 50px;
  }
}

@media (max-width: 500px) {
  .modal__wrapper {
    margin: 20px;
  }

  header {
    padding: 11px 9px 0;
  }

  article {
    padding: 0 24px;
    font-size: 24px;
    line-height: 34px;
  }
}
</style>
