<template>
  <div class="typed-element" ref="typedElement">
    <slot></slot>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import Typed from 'typed.js'

export default defineComponent({
  name: 'AppTyped',
  props: {
    options: {
      type: Object,
      default: () => {},
      required: true
    }
  },

  setup (props) {
    const typedElement = ref()
    const typeObj = ref(null)
    const init = ref(false)
    const initTypedJS = () => {
      if (init.value) return
      init.value = true
      typeObj.value = new Typed(
        typedElement.value.children[0],
        props.options
      )
      return typeObj.value
    }

    const start = () => initTypedJS()
    const reset = () => typeObj.value.reset()
    const destroy = () => {
      init.value = false
      typeObj.value.destroy()
    }

    return {
      typedElement,
      reset,
      destroy,
      start
    }
  }
})
</script>

<style>
.typed-element {
  display: flex;
  align-items: center;
}

.typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
</style>
