<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
import { provide, reactive } from 'vue'
export default {
  name: 'AppAccordion.vue',

  setup () {
    const accordion = reactive({
      count: 0,
      active: null
    })
    provide('Accordion', accordion)
  }
}
</script>

<style scoped>
</style>
