<template>
  <li class="accordion__item" :class="page === 'main' ? 'accordion__item--main' : 'accordion__item--qa'">
    <button
      class="accordion__control"
      :aria-expanded="isOpen"
      aria-controls="accordion__content"
      :class="{'dropdown__control--open': isOpen}"
      @click="open"
    >
      {{ label }}
      <slot name="accordion-control"></slot>
    </button>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="isOpen"
        class="accordion__content"
        :aria-hidden="!isOpen"
      >
        {{ content }}
        <slot name="accordion-content"></slot>
      </div>
    </transition>
  </li>
</template>

<script>
import { inject, ref, computed, onMounted } from 'vue'
export default {
  name: 'AppAccordionItem',

  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    content: {
      type: [Array, String],
      default: '',
      required: false
    },
    page: {
      type: String,
      default: 'qa',
      required: false,
      validator: prop => ['qa', 'main'].includes(prop)
    }
  },

  setup () {
    const accordion = inject('Accordion')
    const index = ref(null)
    const isOpen = computed(() => index.value === accordion.active)

    onMounted(() => {
      index.value = accordion.count++
    })

    const open = () => {
      if (isOpen.value) {
        accordion.active = null
      } else {
        accordion.active = index.value
      }
    }
    const start = (el) => {
      el.style.height = el.scrollHeight + 'px'
    }
    const end = (el) => {
      el.style.height = ''
    }

    return {
      open,
      isOpen,
      start,
      end
    }
  }
}
</script>

<style scoped>
li {
  border-top: 1px solid var(--neutral800);
  border-bottom: 1px solid var(--neutral800);
}

li + li {
  border-top: unset;
}

.accordion__item--qa .accordion__content {
  padding: 0 38px 0 6px;
  font-family: 'Office Times Sharp', serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.accordion__item--main button,
.accordion__item--main .accordion__content {
  font-family: 'Skema Pro Display', serif;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  background: unset;
  border: unset;
  cursor: pointer;
  text-align: left;
}

.accordion__item--qa button {
  padding: 0 20px 0 6px;
  font-family: 'ABC Diatype', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

button:after {
  content: '';
  background: url(../../assets/icons/arrow.svg)no-repeat center/cover;
}

.accordion__item--qa button:after {
  height: 8px;
  width: 14px;
  min-width: 14px;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}

@media (min-width: 799px) {
  .accordion__item--qa .accordion__content {
    font-size: 24px;
    line-height: 32px;
  }

  .accordion__item--qa button {
    height: 64px;
    font-size: 24px;
    line-height: 32px;
  }

  .accordion__item--mid button {
    height: 60px;
  }
}

@media (max-width: 798px) and (min-width: 501px) {
  .accordion__item--qa .accordion__content {
    font-size: 24px;
    line-height: 32px;
  }

  .accordion__item--qa button {
    height: 54px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 501px) {
  .accordion__item--qa .accordion__content {
    font-size: 16px;
    line-height: 21px;
  }

  .accordion__item--qa button {
    height: 54px;
    font-size: 18px;
    line-height: 27px;
  }
}

@media (min-width: 1025px) {
  .accordion__item--main button {
    padding: 12px 0;
  }

  .accordion__item--main button,
  .accordion__item--main .accordion__content {
    font-size: 20px;
  }

  .accordion__item--main button:after {
    height: 8px;
    width: 16px;
    min-width: 16px;
  }
}

@media (max-width: 1024px) {
  .accordion__item--main button {
    padding: 10px 0;
  }

  .accordion__item--main button:after {
    height: 6px;
    width: 12px;
    min-width: 12px;
  }

  .accordion__item--main button,
  .accordion__item--main .accordion__content {
    font-size: 15px;
  }
}
</style>

<style>
@media (min-width: 799px) {
  .accordion__item--qa .accordion__content > *:last-child {
    margin-bottom: 60px;
  }
}

@media (max-width: 798px)  and (min-width: 501px) {
  .accordion__item--qa .accordion__content > *:last-child {
    margin-bottom: 17px;
  }
}

@media (min-width: 501px) {
  .accordion__item--qa .accordion__content > *:first-child {
    margin-top: 17px;
  }
}

@media (max-width: 501px) {
  .accordion__item--qa .accordion__content > *:last-child {
    margin-bottom: 26px;
  }
}

@media (min-width: 1025px) {
  .accordion__item--main .accordion__content > *:first-child {
    margin-top: 17px;
  }

  .accordion__item--main .accordion__content > *:last-child {
    margin-bottom: 60px;
  }
}

@media (max-width: 1024px) and (min-width: 431px) {
  .accordion__item--main .accordion__content > *:first-child {
    margin-top: 17px;
  }

  .accordion__item--main .accordion__content > *:last-child {
    margin-bottom: 17px;
  }
}

@media (max-width: 430px) {
  .accordion__item--main .accordion__content > *:last-child {
    margin-bottom: 26px;
  }
}
</style>
