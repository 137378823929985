<template>
  <img :class="{'logo--animated': isAnimate}" src="../../assets/icons/logo_icon.svg" width="225" height="138" alt="TELEPORT"/>
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    isAnimate: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style scoped>
img {
  margin: 0;
  padding: 0;
  font-size: 19px;
  letter-spacing: 13px;
  text-align: center;
  line-height: 1.2;
  object-fit: contain;
  opacity: .6;
}

.logo--animated {
  animation: 5s linear infinite rotate;
}

@keyframes rotate {
  20%, 100% {
    transform: rotate(180deg);
  }
}
</style>
