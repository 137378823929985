<template>
  <transition name="header">
    <AppHeader
      v-show="isShowHeader"
      :isNavOpen="isNavOpen"
      @goHome="isNavOpen ? isNavOpen=!isNavOpen : ''"
      @changeNavStatus="isNavOpen=!isNavOpen"
    />
  </transition>
  <router-view/>
  <AppFooter
    @changeNavStatus="isNavOpen=!isNavOpen"
  />
  <AppModal />
</template>

<script>
import { ref } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppModal from '@/components/UI/AppModal.vue'
import { isShowHeader } from '@/composition/useState'

export default {
  components: {
    AppModal,
    AppHeader,
    AppFooter
  },

  setup () {
    const isNavOpen = ref(false)
    return { isNavOpen, isShowHeader }
  }
}
</script>

<style>
@import '@/assets/css/font.css';
@import '@/assets/css/base/reset.css';
@import '@/assets/css/base/variables.css';
@import '@/assets/css/text.css';
@import '@/assets/css/buttons.css';

* {
  line-height: 130%;
}

html.overlay-scroll-blocked {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--black);
  font-family: 'Office Times Sharp', Helvetica, Arial, serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
}

a {
  color: var(--white);
  text-decoration: none;
}

.main-link {
  text-transform: uppercase;
}

.main-title {
  width: 100%;
  display: inline-block;
  font-family: 'ABC Diatype';
  font-weight: 400;
  text-align: left;
}

.main__content {
  max-width: calc(1280px + (50px * 2));
  margin: 0 auto;
}

.uniq-text {
  margin-bottom: 40px;
  text-align: left;
  justify-self: left;
  font-family: 'ABC Diatype';
  font-weight: 400;
}

.card {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.error-text {
  font-family: 'ABC Diatype';
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: center;
  color: var(--error);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.header-enter-from,
.header-leave-to
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.header-enter-active {
  transition: opacity 0.5s ease;
}

.no-events {
  pointer-events: none;
}

@media (min-width: 799px) {
  .uniq-text {
    font-size: 36px;
    line-height: 45px;
  }
}

@media (max-width: 798px) and (min-width: 501px) {
  .uniq-text {
    font-size: 31px;
    line-height: 39px;
  }
}

@media (min-width: 501px) {
  .main__content {
    padding: 170px 50px 50px;
  }

  h2.main-title {
    font-size: 18px;
    line-height: 27px;
  }

  h1.main-title {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .card--lg {
    border-radius: 25px;
  }

  .card--sm {
    border-radius: 18px;
  }
}

@media (max-width: 500px) {
  .main__content {
    padding: 109px 20px 100px;
  }

  h2.main-title {
    font-size: 12px;
    line-height: 13px;
  }

  h1.main-title {
    font-size: 10px;
    line-height: 27px;
  }

  .card--lg,
  .card--sm {
    border-radius: 10px;
  }

  .uniq-text {
    font-size: 16px;
    line-height: 21px;
  }
}
</style>
