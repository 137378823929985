import { isShowHeader, isShowIntro } from '@/composition/useState'
import { watch } from 'vue'
let timeout
watch(isShowIntro, (val) => {
  if (val) {
    isShowHeader.value = false
    timeout = setTimeout(() => {
      isShowHeader.value = true
    }, 9500)
  } else {
    clearTimeout(timeout)
    isShowHeader.value = true
  }
})

export const skipIntro = () => {
  sessionStorage.setItem('isIntroShown', 'true')
  isShowIntro.value = false
  isShowHeader.value = true
  toggleHtmlBlock('remove')
}

export const defineIsShowIntro = () => {
  const sessionIntro = sessionStorage.getItem('isIntroShown')
  if (sessionIntro && sessionIntro === 'true') {
    isShowIntro.value = false
  } else {
    toggleHtmlBlock('add')
    isShowIntro.value = true
  }
}

export const toggleHtmlBlock = (action) => {
  const blockClass = 'overlay-scroll-blocked'
  const html = document.getElementsByTagName('html')[0]
  if (action === 'remove') {
    html.classList.remove(blockClass)
  } else if (action === 'add') {
    html.classList.add(blockClass)
  }
}
